<template>
  <div class="no-feature">
    <div
      class="
        event-details
        mx-auto
        row
        pt-4
        px-3 px-md-5
        pb-5
        position-relative
      "
    >
      <div class="navbar-pad"></div>
      <template v-if="!isLoading && event">
        <div class="col px-0 event-video-box">
          <div
            class="
              position-relative
              border
              d-flex
              justify-content-center
              align-items-center
              bg-dark
            "
          >
            <video playsinline id="eventStream">
              <source id="eventStreamSource" type="video/mp4" />
            </video>
            <div
              class="event-video-overlay overflow-hidden"
              v-if="streamStatus != 'live' || !event.stream_key"
            >
              <div
                class="
                  px-3
                  py-5
                  text-center
                  mb-0
                  fw-medium
                  text-muted
                  video-error
                "
                v-if="streamStatus == 'error'"
              >
                <p>
                  <i class="fas fa-exclamation-triangle text-danger me-2" />
                  Error loading video
                </p>
                <button class="btn btn-secondary" @click="refresh()">
                  Retry
                </button>
              </div>
              <div
                class="px-3 py-5 text-center mb-0 text-muted video-error"
                v-else-if="streamStatus == 'end'"
              >
                <p class="mb-0">
                  This session has ended. Thank you for your participation.
                </p>
                <p class="mb-4">See you in next event!</p>
                <router-link
                  class="btn btn-primary px-4"
                  :to="{ params: { id: null } }"
                >
                  Go back
                </router-link>
              </div>
              <img
                v-else-if="event.poster"
                class="video-poster"
                :src="this.Helper.formatMediaUrl(event.poster)"
              />
            </div>
          </div>
          <div
            class="
              py-3
              event-info
              d-flex
              justify-content-between
              align-items-start
              flex-column flex-sm-row
            "
          >
            <p
              class="
                card-title
                lead
                mb-0
                pe-2
                fw-medium
                overflow-hidden
                line-height-reset
              "
            >
              <span class="d-block fw-normal text-muted small">
                {{ event.topic }}
              </span>
              {{ event.title }}<br />
            </p>
            <p class="mb-0 text-start text-sm-end text-nowrap mt-3 mt-sm-0">
              {{ Helper.formatDate(event.start, "ddd DD MMM YYYY") }}
              <span class="d-block small text-muted">
                {{ Helper.formatDate(event.start, "hh:mm A") }}
                <template v-if="event.end">
                  - {{ Helper.formatDate(event.end, "hh:mm A") }}
                </template>
              </span>
            </p>
          </div>
          <div class="mb-4" v-if="eventStatus != 'expired'">
            <span class="badge bg-danger fs-6">LIVE</span>
            <span class="badge bg-primary ms-2 fs-6">
              <i class="fas fa-podcast me-2" />
              {{ currentlyWatching }}
            </span>
          </div>
        </div>
        <div class="col px-0 ps-lg-3 event-comment-box">
          <h6
            class="p-3 mb-0 bg-secondary text-light event-comment-box-title"
          >
            Live Chat
          </h6>
          <div
            id="event-comment-box-body"
            class="p-3 bg-light event-comment-box-body border border-bottom-0"
            @mouseover="shouldScrollMessage = false"
            @mouseleave="shouldScrollMessage = true"
          >
            <p class="text-center text-muted small border rounded bg-white p-3">
              Let's participate.<br />Send your message here
            </p>
            <template v-for="message in messages">
              <p
                class="
                  mb-0
                  d-flex
                  justify-content-between
                  align-items-center
                  small
                "
                :key="`email${message._id}`"
                :class="message.type == 'Admin' ? 'text-primary' : 'text-muted'"
              >
                <span :class="message.type == 'Admin' ? 'fw-medium' : ''">
                  {{ message.name || message.email }}
                </span>
                <span class="text-nowrap">
                  {{ Helper.formatDate(message.createdAt, "h:mm A") }}
                </span>
              </p>
              <p
                class="comment-message w-100 text-wrap mb-3"
                :class="{ 'text-secondary': message.type == 'Admin' }"
                :key="message._id"
              >
                <comment-formatter
                  class="w-100 text-wrap d-inline-block"
                  style="word-wrap: break-word"
                  :text="message.message"
                />
              </p>
            </template>
          </div>
          <div class="event-comment-box-footer border px-3 py-1 bg-white">
            <template v-if="user && eventStatus != 'expired'">
              <input
                v-model="userMessage"
                @keyup.enter="sendMessage()"
                class="form-control border-0 bg-transparent no-focus p-0"
                placeholder="Enter your message"
                type="text"
              />
              <button class="btn btn-white p-0" @click="sendMessage()">
                <i class="fas fa-paper-plane"></i>
              </button>
            </template>
            <div v-else class="text-muted w-100 fst-italic text-center">
              Live chat is disabled
            </div>
          </div>
        </div>
      </template>
      <loading-spinner class="bg-light position-absolute" v-else-if="isLoading">
        Preparing event session for you...
      </loading-spinner>
      <div class="col-12 px-3 px-md-5 py-5 mt-5 text-center" v-else>
        <p class="h5 mb-4">
          We can't find the session you're looking for.<br />
          Head back to all list and select a different session
        </p>
        <router-link
          class="btn btn-primary px-4"
          :to="{ params: { id: null } }"
        >
          Go back
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import flvjs from "flv.js";
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";
import CommentFormatter from "@/components/CommentFormatter";
import io from "socket.io-client";

export default {
  name: "component-event-index",
  components: {
    LoadingSpinner,
    CommentFormatter,
  },
  data() {
    return {
      isLoading: false,
      event: null,
      eventStatus: null,
      streamStatus: null,
      flvPlayer: null,
      timer: null,
      userMessage: "",
      messages: [],
      user: null,
      socket: null,
      shouldScrollMessage: true,
      currentlyWatching: 0,
    };
  },
  watch: {
    "$route.params.id": {
      handler() {
        if (this.$route.params.id) {
          if (this.Helper.validateToken()) {
            this.getEventData();
          } else {
            this.user = null;

            this.Helper.showLoginPrompt(this.$route.path, true, "/events");
          }
        } else {
          this.$router.replace({ name: "Events" });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    closeSocket() {
      if (this.socket) {
        this.socket.offAny();

        this.socket.close();

        this.socket = null;
      }
    },
    connectSocket() {
      this.closeSocket();

      this.socket = io(process.env.VUE_APP_API_URL);

      this.socket.on("chat", (data) => {
        data._id = new Date().valueOf();

        this.messages.push(data);

        this.scrollMessageToBottom();
      });

      this.socket.on("count", (data) => {
        this.currentlyWatching = data;
      });
    },
    scrollMessageToBottom() {
      let messageBox = document.getElementById("event-comment-box-body");

      if (messageBox && this.shouldScrollMessage) {
        this.$nextTick(() => {
          messageBox.scrollTop = messageBox.scrollHeight;
        });
      }
    },
    async loadMessages() {
      const { data } = await this.API.get(
        `webinar-messages?_limit=-1&webinar=${this.event._id}`
      );

      this.messages = data;

      this.scrollMessageToBottom();
    },
    sendMessage() {
      if (this.user && this.userMessage.length > 0) {
        this.API.post("webinar-messages", {
          email: this.user.email,
          name: this.user.name,
          type:
            this.user && this.user.role && this.user.role.name == "Admin"
              ? "Admin"
              : "User",
          message: this.userMessage,
          webinar: this.event._id,
        });
      }

      this.userMessage = "";
    },
    refresh() {
      location.reload();
    },
    async getEventData() {
      this.isLoading = true;

      try {
        const { data } = await this.API.get(
          `webinars/${this.$route.params.id}`
        );

        if (data) {
          const _user = this.$store.state.user;

          if (_user) {
            this.user = _user;

            if (
              data.price > 0 &&
              _user.role.name == "Authenticated" &&
              !data.users.find((user) => user._id == _user._id)
            ) {
              let html = `
                <p class="lh-sm">This is a paid event.<br />Please buy a ticket to join this session.</p>
                <p class="lh-sm mb-0 small alert alert-info">If you already bought one, please contact our admin via call or Whatsapp.</p>
              `;

              Swal.fire("<h5>Wait</h5>", html, "info").then(() => {
                this.$router.replace({ name: "Events" });
              });
            } else {
              this.event = data;

              this.eventStatus = this.Helper.getEventStatus(
                this.event,
                true
              );

              this.streamStatus =
                this.eventStatus == "expired" ? "end" : "waiting";

              this.loadMessages();

              if (this.eventStatus != "expired") {
                this.connectSocket();
              }

              setTimeout(() => {
                // delay to wait for video player available in DOM
                this.loadStream();
              }, 1000);

              if (
                !data.users.map((user) => user._id).includes(this.user._id) &&
                this.user.role.name == "Authenticated"
              ) {
                try {
                  const { data } = await this.API.put(
                    `webinars/${this.event._id}/addParticipant`,
                    {
                      user: this.user._id,
                    }
                  );

                  this.event.users = data.users;
                } catch (error) {
                  console.error("Error add user to event");
                  console.error(error);
                }
              }
            }
          } else {
            this.user = null;
          }
        } else {
          this.event = null;

          this.eventStatus = null;

          this.streamStatus = null;

          this.closeSocket();
        }
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    loadStream() {
      if (this.event.stream_key && flvjs.isSupported()) {
        let videoElement = document.getElementById("eventStream");

        if (typeof this.flvPlayer !== "undefined" && this.flvPlayer != null) {
          this.flvPlayer.unload();
          this.flvPlayer.detachMediaElement();
          this.flvPlayer.destroy();
          this.flvPlayer = null;
        }

        let eventStreamSource = document.getElementById(
          "eventStreamSource"
        );

        videoElement.controls = true;

        videoElement.muted = true;

        eventStreamSource.src = null;

        this.flvPlayer = flvjs.createPlayer({
          type: "flv",
          url: `${process.env.VUE_APP_STREAM_URL}/${this.event.stream_key}.flv`,
          hasAudio: true,
          hasVideo: true,
          isLive: true,
          enableStashBuffer: true,
        });

        this.flvPlayer.attachMediaElement(videoElement);

        this.flvPlayer.load();
      }
    },
    flvLoggingControl(_, str) {
      if (str.includes("onSourceEnded")) {
        this.flvPlayer.pause();

        this.streamStatus = "end";
      }

      if (str.includes("Loader error")) {
        this.loadStream();
      }

      if (str.includes("Received Initialization")) {
        if (typeof this.flvPlayer !== "undefined" && this.flvPlayer != null) {
          this.streamStatus = "live";

          this.flvPlayer.play();

          let isMuted = document.getElementById("eventStream").muted;

          if (isMuted) {
            Swal.fire(
              "The session has started",
              "Click OK to start participating.",
              "success"
            ).then(() => {
              document.getElementById("eventStream").muted = false;
            });
          }
        }
      }
    },
  },
  mounted() {
    flvjs.LoggingControl.addLogListener(this.flvLoggingControl);
  },
  beforeDestroy() {
    if (typeof this.flvPlayer !== "undefined" && this.flvPlayer != null) {
      this.flvPlayer.unload();
      this.flvPlayer.detachMediaElement();
      this.flvPlayer.destroy();
      this.flvPlayer = null;
    }

    flvjs.LoggingControl.removeLogListener(this.flvLoggingControl);

    this.closeSocket();
  },
};
</script>
<template>
  <span v-html="result"></span>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {
      result: "",
    };
  },
  watch: {
    text: {
      handler() {
        if (this.text) {
          let textArray = this.text.split(" ");

          textArray = textArray.map((t) => {
            return t.startsWith("http://") || t.startsWith("https://")
              ? `<a href="${t}" class="small" target="_blank">${t}</a>`
              : t;
          });

          this.result = textArray.join(" ");
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: inherit;
  font-family: inherit;
  white-space: pre-wrap;
}
</style>